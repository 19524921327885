<template>
  <div
    :style="{paddingTop: paddingTop || size}"
    class="text-center"
  >
    <m-travel-icon
      v-if="isTravelIcon"
      :type="icon"
      :size="travelIconSize"
      :color="primaryHex"
    />
    <q-icon
      v-else
      :name="icon"
      :size="size"
      color="primary"
    />
    <h4
      :style="`font-size:${fontSize}px; line-height: ${fontSize * 1.5}px`"
      class="text-faded text-center"
    >
      <slot>{{ label || $t('nothing_found') }}</slot>
    </h4>
  </div>
</template>

<script>
import { MTravelIcon } from 'components'

export default {
  name: 'MEmptyState',
  components: { MTravelIcon },
  props: {
    icon: {
      type: String,
      default: 'help'
    },
    paddingTop: {
      type: String
    },
    size: {
      type: String,
      default: '128px'
    },
    fontSize: {
      type: Number,
      default: 34
    },
    label: {
      type: String,
      default: ''
    },
    isTravelIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    primaryHex () {
      return this.$store.getters.partner.hex
    },
    travelIconSize () {
      const size = this.size.slice(0, -2)
      const bigger = size * 1.5
      return `${bigger}px`
    }
  }
}
</script>
